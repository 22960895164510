import dayjs from "dayjs"

import { CleanUser } from "../types/User"

export const FREE_PROMO_ENDS_SOON_THRESHOLD_WEEKS = 2

export function checkFreePromoEndsSoon(user: CleanUser) {
    return (
        user?.free_promo_active &&
        dayjs().isAfter(dayjs(user?.free_promo_end_date).subtract(FREE_PROMO_ENDS_SOON_THRESHOLD_WEEKS, "weeks"))
    )
}
